<template id="">
  <div>
    <div class="login">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3>パスワード変更</h3>
      <div v-show="$store.state.common.sIsLoading">Loading...</div>
      <div v-show="!$store.state.common.sIsLoading">
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >現在のパスワード</label
                    >
                  </div>
                  <input class="uk-input" type="password" v-model="now_pw" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >新しいパスワード</label
                    >
                  </div>
                  <input class="uk-input" :type="vInputType" v-model="new_pw" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >新しいパスワード(確認用)</label
                    >
                  </div>
                  <input
                    class="uk-input"
                    :type="vInputType"
                    v-model="conf_pw"
                  />
                  <span uk-icon="icon: file-text" @click="doSwitchPassword"
                    >パスワードを表示する</span
                  >
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <button
                    class="uk-button uk-button-secondary uk-width-1-2"
                    type="button"
                    @click="$router.back()"
                  >
                    &lt;&nbsp;戻る
                  </button>
                  <button
                    class="uk-button uk-button-secondary uk-width-1-2"
                    disabled
                    type="button"
                    v-if="flg == false"
                  >
                    更新
                  </button>
                  <button
                    :disabled="$store.state.common.sServerProcessing"
                    class="uk-button uk-button-secondary uk-width-1-2"
                    type="button"
                    @click="change_pw"
                    v-else
                  >
                    更新
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p style="color: red">
          <b>{{ vMessage }}</b>
        </p>
        <hr />
      </div>
    </div>
  </div>
  <!-- <h1>パスワード変更</h1>
  <table style="margin:auto;">
    <tr>
      <td><p style="">現在のパスワード:</p></td>
      <td><input type="password" name="" v-model="now_pw"></td>
    </tr>
    <tr>
      <td><p style="">新パスワード:</p></td>
      <td><input type="password" name="" v-model="new_pw"></td>
    </tr>
    <tr>
      <td><p style="">新パスワード(確認用):</p></td>
      <td><input type="password" name="" v-model="conf_pw"></td>
    </tr>
  </table>
  <div>
    <p style="color:red;" v-if="length_flg==false">文字数が短すぎます(4文字以上)</p>
    <br v-else/>
  </div>
  <button class="uk-button uk-button-default" disabled v-if="flg==false">変更</button>
  <button class="uk-button uk-button-primary" v-else @click="change_pw">変更</button> -->
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      now_pw: null,
      new_pw: null,
      conf_pw: null,
      flg: false,
      length_flg: true,
      tmp: "",
      tmp1: "",
      vMessage: "",
      vInputType: "password",
    };
  },
  mounted() {
    document.title = "パスワード変更";
  },
  methods: {
    doSwitchPassword() {
      if (this.vInputType == "password") {
        this.vInputType = "text";
      } else if (this.vInputType == "text") {
        this.vInputType = "password";
      }
    },
    change_pw() {
      if (this.new_pw.length >= 4) {
        this.length_flg = true;
        this.$store.commit("common/doStartServerProcess");
        let createHash = require("sha256-uint8array").createHash;
        this.tmp = createHash().update(this.now_pw).digest("hex");
        this.tmp1 = createHash().update(this.new_pw).digest("hex");
        this.$axios
          .post("/respondent/changepw", {
            alias_id: sessionStorage.getItem("storageAliasId"),
            email: sessionStorage.getItem("storageId"),
            now_pw: this.tmp,
            new_pw: this.tmp1,
          })
          .then((response) => {
            this.$store.commit("common/doEndServerProcess");
            if (response.data.return_result == 0) {
              this.vMessage = "パスワードを変更しました";
              alert("パスワードの変更が完了しました");
            } else {
              this.vMessage = "パスワードの変更に失敗しました";
              console.log("パスワードの変更に失敗しました");
            }
          })
          .catch((error) => {
            console.log("エラー");
            console.log(error);
          });
        this.$store.commit("common/doEndServerProcess");
      } else {
        this.length_flg = false;
        this.flg = false;
        this.vMessage = "文字数が短すぎます(4文字以上)";
        return;
      }
    },
  },
  watch: {
    now_pw(newVal) {
      //eslint-disable-line
      if (
        this.new_pw == this.conf_pw &&
        newVal != null &&
        newVal != "" &&
        this.new_pw != null &&
        this.new_pw != "" &&
        this.conf_pw != null &&
        this.conf_pw != ""
      ) {
        this.flg = true;
      } else if (
        newVal != this.conf_pw ||
        newVal == null ||
        newVal == "" ||
        this.new_pw == null ||
        this.new_pw == "" ||
        this.conf_pw == null ||
        this.conf_pw == ""
      ) {
        this.flg = false;
      }
    },
    new_pw(newVal) {
      //eslint-disable-line
      if (
        newVal == this.conf_pw &&
        newVal != null &&
        newVal != "" &&
        this.now_pw != null &&
        this.now_pw != ""
      ) {
        this.flg = true;
        this.vMessage = "";
      } else if (
        newVal != this.conf_pw ||
        newVal == null ||
        newVal == "" ||
        this.now_pw == null ||
        this.now_pw == ""
      ) {
        this.flg = false;
        this.vMessage =
          "新しいパスワードと新しいパスワード(確認用)は同じ値を入力してください";
      }
    },
    conf_pw(newVal) {
      //eslint-disable-line
      if (
        newVal == this.new_pw &&
        newVal != null &&
        newVal != "" &&
        this.now_pw != null &&
        this.now_pw != ""
      ) {
        this.flg = true;
        this.vMessage = "";
      } else if (
        newVal != this.new_pw ||
        newVal == null ||
        newVal == "" ||
        this.now_pw == null ||
        this.now_pw == ""
      ) {
        this.flg = false;
        this.vMessage =
          "新しいパスワードと新しいパスワード(確認用)は同じ値を入力してください";
      }
    },
  },
};
</script>

<style scoped>
table tr td p {
  display: inline;
  text-align: right;
}
table tr td {
  text-align: right;
}
</style>
